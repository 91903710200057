import { toast } from "react-toastify";
import { deleteItems, updateStateData } from "../Store/actions";
import store from "../Store/store";
import CampaignIcon from "@mui/icons-material/Campaign";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { DataArray, Insights, ManageAccounts, Settings } from "@mui/icons-material";
import { checkAuthorizedUserDetails, logout, sendApiRequest } from "./requestConfig";
import { capitalizeAndReplaceUnderScore, swapKeyValues, validateEmailFormat } from "./utils";

// const permissionScope = {
//   overview: {
//     can_view: true,
//     can_edit: true,
//   },
//   campaigns: {
//     can_view: true,
//     can_edit: true,
//   },
//   retail_analytics: {
//     can_view: true,
//     can_edit: true,
//   },
//   infrastructure: {
//     can_view: true,
//     can_edit: true,
//   },
//   user_management: {
//     can_view: true,
//     can_edit: true,
//   },
// };

//to validate roles information
const validateRoleInformation = () => {
  const currentStateData = store.getState().userSelections;
  const roleName = typeof currentStateData.new_role_name !== "undefined" ? currentStateData.new_role_name : "";
  const rolePermissions = typeof currentStateData.role_permissions !== "undefined" ? currentStateData.role_permissions : {};

  let error = false;
  //validate role name
  if (roleName === "") {
    error = true;
  }

  //validate role permissions
  if (Object.keys(rolePermissions).length === 0) {
    error = true;
  }

  if (error === true) {
    toast("Please enter the role name and select the permission", { error: true });
    return {
      error: error,
    };
  } else {
    return {
      error: false,
      role_requestInfo: {
        role_name: roleName,
        permissions: rolePermissions,
      },
    };
  }
};

//to validate details when adding a new user
const validateUserInformation = (actionToPerform) => {
  const currentStateData = store.getState().userSelections;
  const userName = typeof currentStateData.username !== "undefined" ? currentStateData.username : "";
  const email = typeof currentStateData.email !== "undefined" ? currentStateData.email : "";
  const userRole = typeof currentStateData.roles !== "undefined" ? currentStateData.roles : [];
  const campaignIds =
    typeof currentStateData.campaign_id !== "undefined" && currentStateData.campaign_id !== null
      ? currentStateData.campaign_id
      : [];
  const cyreenUser = typeof currentStateData.cyreen_user !== "undefined" ? currentStateData.cyreen_user : false;
  // workspace_id workspace_name
  const userWorkspaceId =
    typeof currentStateData.selected_company_id !== "undefined" ? currentStateData.selected_company_id : "";
  const assignedCompanyDetails =
    typeof currentStateData.user_assigned_company !== "undefined" ? currentStateData.user_assigned_company : {};
  // const userWorkspaceName = typeof currentStateData.workspace_name !== "undefined" ? currentStateData.workspace_name : "";
  let error = false;
  let errorMessages = {};
  //validate user name
  if (userName === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      user_name: "Please enter the user name",
    };
  }

  //validate user name
  if (email === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      email: "Please enter the email",
    };
  } else {
    // validate for proper email format
    const validateEmail = validateEmailFormat(email);
    if (validateEmail === false) {
      error = true;
      errorMessages = {
        ...errorMessages,
        email: "Please enter a valid email",
      };
    }
  }
  //validate user name
  if (userRole.length === 0) {
    error = true;
    errorMessages = {
      ...errorMessages,
      user_role: "Please select at least one role",
    };
  }

  //validate role permissions
  // if (Object.keys(userPermissions).length === 0) {
  //   error = true;
  //   errorMessages = {
  //     ...errorMessages,
  //     user_permissions: "Please select the user permissions",
  //   };
  // }

  if (userRole === "Brand" && campaignIds.length === 0) {
    error = true;
    errorMessages = {
      ...errorMessages,
      campaign_id: "Please select the campaign",
    };
  }

  if (
    (cyreenUser === false && typeof userWorkspaceId === "undefined") ||
    (cyreenUser === false && userWorkspaceId === "")
  ) {
    error = true;
    errorMessages = {
      ...errorMessages,
      user_company: "Please select the Company the user belongs to",
    };
  }

  // cyreenUser
  // userWorkspaceId

  if (error === true) {
    toast("Please fill in valid information", { type: "error" });
    return {
      error: error,
      errorMessages,
    };
  } else {
    // userWorkspaceId
    let requestObj = {
      username: userName,
      email: email,
      roles: userRole,
      campaign_ids: campaignIds,
      cyreen_user: cyreenUser,
    };
    if (userWorkspaceId !== "") {
      requestObj = {
        ...requestObj,
        workspace_id: userWorkspaceId,
      };
    }
    // add assigned company obj if action = update
    if (actionToPerform === "update" && cyreenUser === false) {
      requestObj = {
        ...requestObj,
        assigned_company: assignedCompanyDetails,
      };
    }
    return {
      error: false,
      user_requestInfo: requestObj,
    };
  }
};

// to fetch a list of users
export const fetchUsers = async () => {
  const getUsersList = await sendApiRequest("users", {}, "GET");
  if (typeof getUsersList.success !== "undefined" && getUsersList.success === true) {
    const usersList = getUsersList.response;

    let filteredLst = [];
    let filteredCyreenUsers = [];
    let userIdandNameList = {};
    usersList.forEach((user) => {
      if (user.email !== "" && user.email !== null) {
        let restructuredUserObj = {
          id: user.id,
          assigned_company: user.assigned_company,
          username: user.username,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          roles: user.roles.sort((next, prev) => {
            return next.localeCompare(prev);
          }),
          retailer_ids: user.retailer_ids,
          user_permissions: user.permissions,
          user_type: user.user_type,
          cyreen_user: user.cyreen_user,
          campaign_id: user.campaign_ids,
          workspace_id: user.workspace_id,
          brand_campaign_selection:
            typeof user.campaigns !== "undefined" && user.campaigns !== null
              ? user.campaigns.map((campaign) => {
                return { id: campaign.id, label: campaign.name };
              })
              : [],
        };

        if (user.user_type === "cyreen") {
          filteredCyreenUsers.push(restructuredUserObj);
        } else {
          filteredLst.push(restructuredUserObj);
        }

        userIdandNameList =
          user.role_name === "Retailer"
            ? {
              ...userIdandNameList,
              [user.id]: user.username,
            }
            : { ...userIdandNameList };
      }
    });

    store.dispatch(
      updateStateData({
        users_list: filteredLst,
        cyreen_users: filteredCyreenUsers,
        user_name_id_list: userIdandNameList,
      })
    );
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

// to fetch a list of users that can be assigned to a specific role.
export const fetchAssignableUsers = async (roleId) => {
  const getUsersList = await sendApiRequest("users/assignable/" + roleId, {}, "GET");
  if (typeof getUsersList.success !== "undefined" && getUsersList.success === true) {
    const usersList = getUsersList.response;

    let filteredLst = [];
    let filteredCyreenUsers = [];
    usersList.forEach((user) => {
      if (user.email !== "" && user.email !== null) {
        let restructuredUserObj = {
          id: user.id,
          assigned_company: user.assigned_company,
          username: user.username,
          email: user.email,
          roles: user.roles.sort((next, prev) => {
            return next.localeCompare(prev);
          }),
        };

        if (user.user_type === "cyreen") {
          filteredCyreenUsers.push(restructuredUserObj);
        } else {
          filteredLst.push(restructuredUserObj);
        }
      }
    });

    store.dispatch(
      updateStateData({
        assignable_users_list: filteredLst,
        assignable_cyreen_users: filteredCyreenUsers,
      })
    );
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

export const fetchRoleDetail = async (role) => {
  let detailObj = {};
  // request additional info for each role
  const getRolesDetail = await sendApiRequest("users/roles/" + role.id, {}, "GET");
  if (typeof getRolesDetail.success !== "undefined" && getRolesDetail.success === true) {
    detailObj = {
      ...role,
      assigned_users: getRolesDetail.response.assigned_users,
      assigned_users_count: getRolesDetail.response.assigned_users_count,
      manage: getRolesDetail.response.manage,
      view: getRolesDetail.response.view,
    };

    return detailObj;
  } else {
    return {};
  }
};

// fetch list of available roles
export const fetchRoles = async (fetchRelatedInfo, requestedFor) => {
  // reset roles data in redux before fetching new data
  store.dispatch(deleteItems(["role_list", "role_name_arr"]));
  // request end point
  const endpoint = typeof requestedFor !== "undefined" && requestedFor !== "" ? "users/roles/list/" + requestedFor : "users/roles/list";
  // fetch roles list
  const getRolesList = await sendApiRequest(endpoint, {}, "GET");
  if (typeof getRolesList.success !== "undefined" && getRolesList.success === true) {
    let rolesList = getRolesList.response;
    // Fetch details for each role and update the list
    if (fetchRelatedInfo === true) {
      const rolesWithDetails = await Promise.all(
        rolesList.map(async (role) => {
          const roleDetail = await fetchRoleDetail(role);
          return { ...role, ...roleDetail };
        })
      );
      store.dispatch(
        updateStateData({
          role_list: rolesWithDetails,
          role_name_arr: rolesWithDetails.map((role) => role.role_name),
        })
      );
    } else {
      store.dispatch(
        updateStateData({
          role_list: rolesList,
          role_name_arr: rolesList.map((role) => role.role_name),
        })
      );
    }

    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

export const assignRoleToUsers = async (userInfo, roleId) => {
  const assignRole = await sendApiRequest("users/roles/" + roleId + "/assign", userInfo, "POST");
  if (typeof assignRole.success !== "undefined" && assignRole.success === true) {
    if (assignRole.response.success === true) {
      fetchRoles(true);
      fetchUsers();
      return {
        success: true,
      };
    } else {
      toast("Sorry, something went wrong", { type: "error" });
    }
  } else {
    toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};

export const deleteUser = async (userToDelete) => {
  const deleteRecord = await sendApiRequest(
    "users/delete",
    { user_id: userToDelete.id, email: userToDelete.email, workspace_id: userToDelete.assigned_company.id },
    "POST"
  );
  if (typeof deleteRecord.success !== "undefined" && deleteRecord.success === true) {
    if (deleteRecord.response.success === true) {
      fetchUsers();
      return {
        success: true,
      };
    } else {
      toast(deleteRecord.response.error_detail, { type: "error" });
    }
  } else {
    toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};

// to fetch the list of companies
export const fetchCompanies = async () => {
  const getCompaniesList = await sendApiRequest("companies", {}, "GET");
  if (typeof getCompaniesList.success !== "undefined" && getCompaniesList.success === true) {
    fetchBrands();
    const companiesList = getCompaniesList.response;
    let filtredRetailerList = [];
    let companiesNameIds = [];
    companiesList.forEach((company) => {
      companiesNameIds.push({ label: company.name, id: company.id });
      filtredRetailerList.push({ label: company.name, id: company.id, tree_path: company.tree_path });
    });

    store.dispatch(
      updateStateData({
        company_name_ids: companiesNameIds,
        retailers_list: filtredRetailerList,
      })
    );
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

// to fetch the list of companies
export const fetchStoreLevelCompanies = async () => {
  const getCompaniesList = await sendApiRequest("companies/store-level", {}, "GET");
  if (typeof getCompaniesList.success !== "undefined" && getCompaniesList.success === true) {
    fetchBrands();
    const companiesList = getCompaniesList.response;
    let filtredRetailerList = [];
    let companiesNameIds = [];
    companiesList.forEach((company) => {
      companiesNameIds.push({ label: company.name, id: company.id });
      filtredRetailerList.push({ label: company.name, id: company.id, tree_path: company.tree_path });
    });

    store.dispatch(
      updateStateData({
        store_level_company_name_ids: companiesNameIds,
        store_level_retailers_list: filtredRetailerList,
      })
    );
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

export const fetchBrands = async () => {
  const getCompaniesList = await sendApiRequest("companies/advertisers", {}, "GET");
  if (typeof getCompaniesList.success !== "undefined" && getCompaniesList.success === true) {
    const companiesList = getCompaniesList.response;
    let filteredBrandList = [];
    let filteredBrandNames = [];

    companiesList.forEach((company) => {
      filteredBrandList.push({ label: company.name, id: company.id });
      filteredBrandNames.push(company.name);
    });
    filteredBrandList.push({ label: "Add new", id: "new" });
    store.dispatch(
      updateStateData({
        brand_list: filteredBrandList,
        brand_names_arr: filteredBrandNames,
      })
    );
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

// to save new user details
export const saveUserInformation = async (actionToPerform, actionDetail) => {
  const validateUser = validateUserInformation(actionToPerform);
  // check for errors
  if (validateUser.error === false) {
    let apiSuccessResponse = "";
    if (actionToPerform === "add") {
      //save user information
      const saveUserInfo = await sendApiRequest("users/add", validateUser.user_requestInfo, "POST");
      apiSuccessResponse = saveUserInfo.response;
    }

    if (actionToPerform === "update") {
      let requestBody = {
        ...validateUser.user_requestInfo,
        id: actionDetail.id,
      };
      //save user information
      const saveUserInfo = await sendApiRequest("users/update", requestBody, "POST");
      apiSuccessResponse = saveUserInfo.response;
    }

    if (apiSuccessResponse.success === true) {
      fetchUsers();
      return {
        success: true,
      };
    } else {
      const toastMessage =
        typeof apiSuccessResponse.error_detail !== "undefined" && apiSuccessResponse.error_detail !== ""
          ? apiSuccessResponse.error_detail
          : "Sorry, something went wrong.";
      toast(toastMessage, { type: "error" });
      return {
        success: false,
        errorMessages: {},
      };
    }
  } else {
    return {
      success: false,
      errorMessages: validateUser.errorMessages,
    };
  }
};

export const checkUserRole = () => {
  //check if user permission cookie is available or not.
  if (typeof document.cookie.split("; ").find((row) => row.startsWith("userRole")) !== "undefined") {
    const role = JSON.parse(
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("userRole"))
        .split("=")[1]
    );

    return {
      success: true,
      user_role: role,
    };
  } else {
    // if cookie doesn't exist, then we have to ask user to login again so that correct user permissions can be fetched and used for all the features to work correctly
    logout();
  }
};

// fetch user detail from the cookie
export const checkUserDetailsFromCookie = () => {
  //check if user permission cookie is available or not.
  if (typeof document.cookie.split("; ").find((row) => row.startsWith("userDetail")) !== "undefined") {
    const details = JSON.parse(
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("userDetail"))
        .split("=")[1]
    );

    return {
      success: true,
      user_detail: details,
    };
  } else {
    // if cookie doesn't exist, then we have to ask user to login again so that correct user permissions can be fetched and used for all the features to work correctly
    logout();
  }
};

export const checkUserPermissions = () => {
  //check if user permission cookie is available or not.
  if (typeof document.cookie.split("; ").find((row) => row.startsWith("userPermissions")) !== "undefined") {
    const permissionScope = JSON.parse(
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("userPermissions"))
        .split("=")[1]
    );
    const userRole = checkUserRole();

    const userDetails = checkUserDetailsFromCookie();

    return {
      success: true,
      allowed_access: permissionScope,
      user_role: userRole.user_role,
      user_type: userDetails.success === true ? userDetails.user_detail.user_type : "",
    };
  } else {
    // if cookie doesn't exist, then we have to ask user to login again so that correct user permissions can be fetched and used for all the features to work correctly
    logout();
  }
};

export const allowedNavOptions = async (location) => {
  //check user permissions.
  const permissions = checkUserPermissions();
  const checkUserDetails = checkUserDetailsFromCookie();

  if (permissions.success === true) {
    const permissionScope = permissions.allowed_access;
    const userRole = permissions.user_role;
    const userType = checkUserDetails.success === true ? checkUserDetails.user_detail.user_type : "";

    // Define an array of navigation items with their paths
    let navItems = [];
    let deactivatedSections = checkUserDetails.success === true ? checkUserDetails.user_detail.deactivated_sections : [];
    // ["overview", "campaigns_section", "campaign_list", "clips", "pictures", "retail_analytics_section", "general_analytics", "instore_duration", "profitability", "trolley_usage", "heatmap", "optimization", "infrastructure_section", "stores_infra", "monitoring_infra", "data_exchange_section", "data_exchange_upload", "data_exchange_download", "user_section", "user_list", "role_list"];
    // overview
    if ((userType === "cyreen" || !deactivatedSections.includes("overview")) && permissionScope.overview_can_view === true) {
      navItems = [
        ...navItems,
        {
          path: "/cap-overview",
          text: "Overview",
          icon: <DashboardIcon />,
          nested_list: false,
          active: ["/cap-overview"].includes(location.pathname),
        },
      ];
    }
    // campaigns
    if (
      (userType === "cyreen" || !deactivatedSections.includes("campaigns_section")) &&
      (permissionScope.campaign_list_can_view === true ||
        permissionScope.clip_list_can_view === true ||
        permissionScope.picture_list_can_view === true)
    ) {
      let roleBasedAccessItems = [];

      if ((userType === "cyreen" || !deactivatedSections.includes("campaign_list")) && permissionScope.campaign_list_can_view === true) {
        roleBasedAccessItems = [
          ...roleBasedAccessItems,
          {
            path: "/check-campaigns/list",
            text: "Campaign List",
          },
        ];
      }

      if ((userType === "cyreen" || !deactivatedSections.includes("clips")) && permissionScope.clip_list_can_view === true) {
        roleBasedAccessItems = [
          ...roleBasedAccessItems,
          {
            path: "/check-campaigns/clips",
            text: "Clips",
          },
        ];
      }
      if ((userType === "cyreen" || !deactivatedSections.includes("pictures")) && permissionScope.picture_list_can_view === true) {
        roleBasedAccessItems = [
          ...roleBasedAccessItems,
          {
            path: "/check-campaigns/pictures",
            text: "Pictures",
          },
        ];
      }
      navItems = [
        ...navItems,
        {
          path: "/check-campaigns/list",
          text: "Campaigns",
          icon: <CampaignIcon />,
          nested_list: true,
          nested_keyword: "campaign_sub_menu",
          active: [
            "/check-campaigns/list",
            "/check-campaigns/clips",
            "/check-campaigns/pictures",
            "/check-campaigns/Companies",
            "/check-campaigns/Contacts",
            "/check-campaigns/dashboard",
          ].includes(location.pathname),
          nested_items: roleBasedAccessItems,
        },
      ];
    }
    // retail analytics
    if ((userType === "cyreen" || !deactivatedSections.includes("retail_analytics_section")) && permissionScope.analytics_can_view === true && userType !== "national") {
      // temporary addition of a dashboard specially for edeka..
      let nestedItemsList = []
      // general analytics
      if (userType === "cyreen" || !deactivatedSections.includes("general_analytics")) {
        nestedItemsList = [
          ...nestedItemsList,
          {
            path: "/analytics/general-kpi",
            text: "General KPIs",
          },
        ];
      }
      // instore duration
      if (userType === "cyreen" || !deactivatedSections.includes("instore_duration")) {
        nestedItemsList = [
          ...nestedItemsList,
          {
            path: "/analytics/duration",
            text: "Instore Duration",
          },
        ];
      }
      // profitability
      if (userType === "cyreen" || !deactivatedSections.includes("profitability")) {
        nestedItemsList = [
          ...nestedItemsList,
          {
            path: "/analytics/profitability",
            text: "Profitability",
          },
        ];
      }
      // trolley usage
      if (userType === "cyreen" || !deactivatedSections.includes("trolley_usage")) {
        nestedItemsList = [
          ...nestedItemsList,
          {
            path: "/analytics/trolley-usage",
            text: "Trolley Usage",
          },
        ];
      }
      // heatmap
      if (userType === "cyreen" || !deactivatedSections.includes("heatmap")) {
        nestedItemsList = [
          ...nestedItemsList,
          {
            path: "/analytics/heat-map",
            text: "Heatmap",
          },
        ];
      }
      // optimization
      if (userType === "cyreen" || !deactivatedSections.includes("optimization")) {
        nestedItemsList = [
          ...nestedItemsList,
          {
            path: "/analytics/optimization",
            text: "Optimization",
          },
        ];
      }

      // root company id for edeka is 74, so we are hardcoding it to specifically show it to edeka.
      if (checkUserDetails.success === true && checkUserDetails.user_detail.root_company_id === 74) {
        nestedItemsList = [
          ...nestedItemsList,
          {
            path: "/analytics/special-analytics",
            text: "Special Analytics",
          },
        ];
      }
      navItems = [
        ...navItems,
        {
          path: "/analytics/general-kpi",
          text: "Retail Analytics",
          icon: <Insights />,
          nested_list: true,
          nested_keyword: "retail_analytics",
          active: [
            "/analytics/general-kpi",
            "/analytics/profitability",
            "/analytics/trolley-usage",
            "/analytics/duration",
            "/analytics/heat-map",
            "/analytics/optimization",
            "/analytics/special-analytics",
          ].includes(location.pathname),
          nested_items: nestedItemsList,
        },
      ];
    }
    // infrastructure
    if ((userType === "cyreen" || !deactivatedSections.includes("infrastructure_section")) &&
      (permissionScope.infra_store_list_can_view === true ||
        (permissionScope.infra_store_monitoring_can_view === true && userType !== "national"))
    ) {
      let nestedInfraList = [];
      if ((userType === "cyreen" || !deactivatedSections.includes("stores_infra")) && permissionScope.infra_store_list_can_view === true) {
        nestedInfraList = [
          ...nestedInfraList,
          {
            path: "/infrastructure/locations",
            text: "Stores",
          },
        ];
      }

      if ((userType === "cyreen" || !deactivatedSections.includes("monitoring_infra")) && permissionScope.infra_store_monitoring_can_view === true) {
        nestedInfraList = [
          ...nestedInfraList,
          {
            path: "/infrastructure/monitoring",
            text: "Monitoring",
          },
        ];
      }
      navItems = [
        ...navItems,
        {
          path: "/infrastructure/locations",
          text: "Infrastructure",
          icon: <Settings />,
          nested_list: true,
          nested_keyword: "infrastructure_sub_menu",
          active: [
            "/infrastructure/locations",
            "/infrastructure/devices",
            "/infrastructure/monitoring",
            "/infrastructure/configure-location",
          ].includes(location.pathname),
          nested_items: nestedInfraList,
        },
      ];
    }
    // Data Exchange
    if (
      (userType === "cyreen" || !deactivatedSections.includes("data_exchange_section")) &&
      (permissionScope.data_exchange_upload_can_view === true ||
        permissionScope.data_exchange_download_can_view === true)
    ) {
      navItems = [
        ...navItems,
        {
          path: "/data-exchange",
          text: "Data Exchange",
          icon: <DataArray />,
          nested_list: false,
          nested_keyword: "data_exchange_menu",
          active: ["/data-exchange"].includes(location.pathname),
          nested_items: [
            {
              path: "/data-exchange",
              text: "Data Exchange",
            },
          ],
        },
      ];
    }

    // users list
    if ((userType === "cyreen" || !deactivatedSections.includes("user_section")) && permissionScope.user_list_can_view === true) {
      let nestedUserSections = [];
      if (userType === "cyreen" || !deactivatedSections.includes("user_list")) {
        nestedUserSections = [
          ...nestedUserSections,
          {
            path: "/users/list",
            text: "User List",
          },
        ];
      }
      if (userType === "cyreen" || !deactivatedSections.includes("role_list")) {
        nestedUserSections = [
          ...nestedUserSections,
          {
            path: "/users/role",
            text: "Roles",
          },
        ];
      }
      navItems = [
        ...navItems,
        {
          path: "/users/list",
          text: "Users",
          icon: <ManageAccounts />,
          nested_list: true,
          nested_keyword: "user_management_menu",
          active: ["/users/list", "/users/role"].includes(location.pathname),
          nested_items: nestedUserSections,
        },
      ];
    }

    return {
      success: true,
      navItems: navItems,
    };
  } else {
    // if cookie doesn't exist, then we have to ask user to login again so that correct user permissions can be fetched and used for all the features to work correctly
  }
};

// export const pageAccessAllowed = (routeToAccess, whichAction = "can_view") => {
//   //check allowed access
//   const permissions = checkUserPermissions();

//   if (permissions.success === true) {
//     const permissionScope = permissions.allowed_access;

//     if (permissionScope[routeToAccess][whichAction] === false) {
//       return {
//         success: false,
//         message: "Sorry, you are not allowed to access this page",
//       };
//     } else {
//       return {
//         success: true,
//         message: "access allowed",
//       };
//     }
//   } else {
//     return {
//       success: false,
//       message: "Sorry, something went wrong",
//     };
//   }
// };
export const pageAccessAllowed = (routeToAccess, allowedUserTypes = []) => {
  // return {
  //   success: true,
  //   message: "Access allowed",
  // };
  // Check allowed access
  const permissions = checkUserPermissions();

  if (permissions.success === true) {
    const permissionScope = permissions.allowed_access;
    const userType = permissions.user_type;

    // Check if user type is allowed
    if (allowedUserTypes.length > 0 && !allowedUserTypes.includes(userType)) {
      return {
        success: false,
        message: "Sorry, your user type is not allowed to access this page",
      };
    }

    // loop through requested access rights and return the response accordingly
    let allowed = false;
    routeToAccess.forEach((access_type) => {
      if (permissionScope[access_type] === true) {
        allowed = true;
      }
    });

    if (allowed === true) {
      return {
        success: true,
        message: "Access allowed",
      };
    } else {
      return {
        success: false,
        message: "Sorry, you are not allowed to access this page",
      };
    }
  } else {
    return {
      success: false,
      message: "Sorry, something went wrong",
    };
  }
};

// to reset password for the logged user
export const resetUserPassword = async (passwordInfo) => {
  //update password
  const updateInfo = await sendApiRequest("users/update-password", { password: passwordInfo }, "POST");
  if (typeof updateInfo.success !== "undefined" && updateInfo.success === true) {
    logout();
  } else {
    toast("Sorry, something went wrong.", { type: "error" });
  }
};

// to send forgot password email
export const forgotUserPassword = async (userEmail) => {
  //update password
  const sendInfo = await sendApiRequest("authenticate/password-reset", { email: userEmail }, "POST");

  if (typeof sendInfo.success !== "undefined" && sendInfo.success === true) {
    toast(sendInfo.response.message, { type: "success" });
  } else {
    toast("Sorry, something went wrong.", { type: "error" });
  }
};

// switch workspace
export const switchWorkspace = async (workspaceId, navigate, usedFor) => {
  //update password
  const sendInfo = await sendApiRequest("workspaces/switch/" + workspaceId, {}, "POST");

  if (typeof sendInfo.success !== "undefined" && sendInfo.success === true) {
    if (usedFor === "update") {
      toast(sendInfo.response.message, { type: "success" });
    }

    store.dispatch(
      updateStateData({
        show_workspace_list: false,
      })
    );
    checkAuthorizedUserDetails(navigate);
  } else {
    toast("Sorry, something went wrong.", { type: "error" });
  }
};

export const checkWorkSpaceFromCookies = () => {
  let currentWorkSpace = {};
  let workspaceOptions = [];
  //check if user permission cookie is available or not.
  if (typeof document.cookie.split("; ").find((row) => row.startsWith("workspace_options")) !== "undefined") {
    workspaceOptions = JSON.parse(
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("workspace_options"))
        .split("=")[1]
    );

    if (typeof document.cookie.split("; ").find((row) => row.startsWith("current_workspace")) !== "undefined") {
      currentWorkSpace = JSON.parse(
        document.cookie
          .split("; ")
          .find((row) => row.startsWith("current_workspace"))
          .split("=")[1]
      );
    }

    return {
      success: true,
      workspace_options: workspaceOptions,
      current_workspace: currentWorkSpace,
    };
  } else {
    return {
      success: false,
      workspace_options: [],
      current_workspace: {},
    };
  }
};
