import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, InputAdornment, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { Add, AddCircle, ArrowDropDown, ArrowDropUp, Close, Delete, Done, Edit, Error, Save } from "@mui/icons-material";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import { deleteItems, updateStateData } from "../../../Store/actions";
import {
  checkUncheckAllCheckboxes,
  validate_adlevel_accuracy,
  validate_adlevel_for_all_checks,
} from "../../../api_calls/campaignSheet";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { MediumWidthStyledTableCell, StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import {
  HtmlTooltip,
  HtmlTooltipDisabled,
  IconContainer,
  StyledCheckbox,
  StyledStack,
  StyledWhiteCheckbox,
} from "../../../components/commonComponents/StyledComponents/styled";

import { CustomTextInput, CustomTextInputLeftAligned } from "../../../components/commonComponents/TextInputs/style";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";

import { toast } from "react-toastify";
import { deleteMultipleAdlevels, saveSingleAdlevelRow, updateCampaignAdlevels } from "../../../api_calls/campaignRequests";
import DownloadCSV from "../../../components/commonComponents/DownloadCsv/DownloadCSV";
import { DisabledGreyButton } from "../../../components/commonComponents/Buttons/DisabledButton";
import { loadMoreData } from "../../../api_calls/utils";
import CommonConfirmation from "../../../components/commonComponents/CommonConfirmation";
import Pagination from "../../../components/commonComponents/LoadMore/Pagination";

const AdlevelSheetResults = (props) => {
  const sheetDetails = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const [editRow, setEditRow] = React.useState(false);
  const [editRowIndex, setEditRowIndex] = React.useState("");
  //use react state to manage product selections for creating adlevels.
  const [productSelection, setProductSelection] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  // to show error when there's any inaccuracy found in ad level names and numbers
  const [accuracyErrors, setAccuracyErrors] = React.useState({});
  const [error, setError] = React.useState(false);
  const readCampaignStatus =
    typeof sheetDetails.campaign_detail !== "undefined" &&
    ![2, 4].includes(sheetDetails.campaign_detail.campaign_status_code)
      ? "requested"
      : "open";

  const [searchVal, setSearchVal] = React.useState({ value: "" });
  const [editedRowValues, setEditedRowValues] = React.useState({});
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [addNewRow, setAddNewRow] = React.useState({
    add: false,
    addFrom: "",
  });
  const [newRowValues, setNewRowValues] = React.useState({
    record_index: "",
    nameCompany: "",
    campaign: "",
    bundleSize: "",
    nameAdLevel: "",
    numberAdLevel: "",
    gtin: "",
    description: "",
  });
  // sorting measures for files listing
  const [sortingMeasure, setSortingMeasure] = React.useState({
    nameAdLevel: "asc",
    numberAdLevel: "asc",
    description: "asc",
    gtin: "asc",
  });
  const [sortingParamName, setSortingParamName] = React.useState("numberAdLevel");

  const paginationStartValue =
    typeof sheetDetails.pagination !== "undefined" ? sheetDetails.pagination.analysis_import_start : 0;
  const paginationEndValue =
    typeof sheetDetails.pagination !== "undefined" ? sheetDetails.pagination.analysis_import_end : 10;

  const listingRecords = typeof sheetDetails.created_adlevels !== "undefined" ? sheetDetails.created_adlevels : [];

  // to return the sorted list items
  const sortedResults = (toBeSortedList) => {
    return toBeSortedList.slice().sort((a, b) => {
      if (sortingParamName === "numberAdLevel") {
        return sortingMeasure.numberAdLevel === "asc"
          ? parseInt(a.numberAdLevel) - parseInt(b.numberAdLevel)
          : parseInt(b.numberAdLevel) - parseInt(a.numberAdLevel);
      } else if (sortingParamName === "nameAdLevel") {
        return sortingMeasure.nameAdLevel === "asc"
          ? a.nameAdLevel.localeCompare(b.nameAdLevel)
          : b.nameAdLevel.localeCompare(a.nameAdLevel);
      } else if (sortingParamName === "description") {
        return sortingMeasure.description === "asc"
          ? a.description.localeCompare(b.description)
          : b.description.localeCompare(a.description);
      } else {
        return sortingMeasure.gtin === "asc" ? parseInt(a.gtin) - parseInt(b.gtin) : parseInt(b.gtin) - parseInt(a.gtin);
      }
    });
  };
  //select records from redux
  const sheetRecords =
    typeof searchVal.value !== "undefined" && searchVal.value !== ""
      ? sortedResults(
          listingRecords
            .filter((sheetData) => {
              if (parseInt(searchVal.value) !== "NaN" && searchVal.value.length <= 2) {
                return sheetData.numberAdLevel.toString().includes(searchVal.value.toString());
              } else {
                return (
                  sheetData.nameAdLevel.toLowerCase().includes(searchVal.value.toLowerCase().toString()) ||
                  sheetData.numberAdLevel.toString().includes(searchVal.value.toString()) ||
                  sheetData.description.toLowerCase().includes(searchVal.value.toLowerCase().toString()) ||
                  sheetData.gtin.toString().includes(searchVal.value.toString())
                );
              }
            })
            .slice(paginationStartValue, paginationEndValue)
        )
      : sortedResults(listingRecords).slice(paginationStartValue, paginationEndValue);

  // will be used when we approve edited changes in the edit row format
  const handleFieldChange = async (dataIndex) => {
    const adlevelRecords = typeof sheetDetails.created_adlevels !== "undefined" ? sheetDetails.created_adlevels : [];
    setError(false);
    setAccuracyErrors({});
    // validate edited values
    const checkValidity = validate_adlevel_for_all_checks(editedRowValues.edited_values);
    if (checkValidity.error === true) {
      setError(true);
      setAccuracyErrors(checkValidity.messagesObj);
    } else {
      // validate accuracy
      const checkAccuracy = validate_adlevel_accuracy(
        editedRowValues.edited_values,
        adlevelRecords.filter((item) => {
          return item.record_index !== dataIndex;
        })
      );

      if (checkAccuracy.error === true) {
        setError(true);
        setAccuracyErrors(checkAccuracy.messagesObj);
      } else {
        const saveRecords = await updateCampaignAdlevels([editedRowValues.edited_values]);
        if (saveRecords.success === true) {
          // check if any error returned from the api
          if (
            typeof saveRecords.api_response !== "undefined" &&
            typeof saveRecords.api_response.response.success !== "undefined" &&
            saveRecords.api_response.response.success === false
          ) {
            toast(saveRecords.api_response.response.error_detail, { type: "error" });
          } else {
            const updatedAdlevelSheetRecords = [
              ...adlevelRecords.slice(0, dataIndex), // Copy elements before the updated index
              editedRowValues.edited_values, // Update the element at the specified index
              ...adlevelRecords.slice(dataIndex + 1), // Copy elements after the updated index
            ];

            dispatchAction(
              updateStateData({
                created_adlevels: updatedAdlevelSheetRecords,
              })
            );

            setEditRow(false);
            setEditRowIndex("");
            setEditedRowValues({});
            props.trackChangesInProgress(false);
            //show success notification
            const message = "Analysis group updated successfully.";
            toast(message, { type: "success" });
            //reset filter and reset all selected checkboxes
            checkUncheckAllCheckboxes(false);
          }
        }
      }
    }
  };

  // will be used to add a new adlevel records
  const addNewRecord = async (addFrom) => {
    const adlevelRecords = typeof sheetDetails.created_adlevels !== "undefined" ? sheetDetails.created_adlevels : [];
    setError(false);
    setAccuracyErrors({});
    const checkValidity = validate_adlevel_for_all_checks(newRowValues);
    if (checkValidity.error === true) {
      setError(true);
      setAccuracyErrors(checkValidity.messagesObj);
    } else {
      // validate accuracy
      const checkAccuracy = validate_adlevel_accuracy(newRowValues, adlevelRecords);

      if (checkAccuracy.error === true) {
        setError(true);
        setAccuracyErrors(checkAccuracy.messagesObj);
      } else {
        const saveRecords = await saveSingleAdlevelRow(
          props.campaignId,
          [newRowValues],
          sheetDetails.campaign_detail.campaign_status_code
        );
        if (saveRecords.success === true) {
          // check if any error returned from the api
          if (
            typeof saveRecords.api_response !== "undefined" &&
            typeof saveRecords.api_response.response.success !== "undefined" &&
            saveRecords.api_response.response.success === false
          ) {
            toast(saveRecords.api_response.response.error_detail, { type: "error" });
          } else {
            const updatedAdlevelSheetRecords =
              addFrom === "top"
                ? [
                    newRowValues, // add new record to the top
                    ...adlevelRecords,
                  ]
                : [
                    ...adlevelRecords,
                    newRowValues, // add new record to the end
                  ];

            dispatchAction(
              updateStateData({
                created_adlevels: updatedAdlevelSheetRecords.map((value, key) => {
                  return {
                    ...value,
                    record_index: key,
                  };
                }),
              })
            );

            // reset uploaded file data (if any)
            dispatchAction(deleteItems(["adlevel_sheet_data", "uploaded_sheet_errors"]));
            props.controlAcceptedFiles([]);
            props.controlRejectedFiles([]);

            setAddNewRow({
              add: false,
              addFrom: "",
            });

            setNewRowValues({
              record_index: "",
              nameCompany: "",
              campaign: "",
              bundleSize: "",
              nameAdLevel: "",
              numberAdLevel: "",
              gtin: "",
              description: "",
            });
            props.trackChangesInProgress(false);
            const message = "Analysis group added successfully.";
            toast(message, { type: "success" });
          }
        }
      }
    }
  };

  const afterDeleteAction = () => {
    const adlevelRecords = typeof sheetDetails.created_adlevels !== "undefined" ? sheetDetails.created_adlevels : [];
    const newAdlevelList = adlevelRecords.filter((sheetRecord) => {
      return !productSelection.includes(sheetRecord.record_index);
    });
    // update redux state
    dispatchAction(
      updateStateData({
        created_adlevels: newAdlevelList.map((value, key) => {
          return {
            ...value,
            record_index: key,
          };
        }),
      })
    );
    checkUncheckAllCheckboxes(false);
    //reset product selection
    setProductSelection([]);
    setSelectAll(false);
    //show success notification
    toast("Analytics product deleted successfully.", { type: "success" });
  };

  // delete selected records
  const deleteSelectedItems = async () => {
    const adlevelRecords = typeof sheetDetails.created_adlevels !== "undefined" ? sheetDetails.created_adlevels : [];
    let toBeDeletedRecordIds = [];
    let recordsWithoutId = false;
    let recordsWithId = false;

    adlevelRecords.forEach((record) => {
      if (productSelection.includes(record.record_index)) {
        if (typeof record.id !== "undefined") {
          recordsWithId = true;
          toBeDeletedRecordIds.push({
            id: record.id,
          });
        } else {
          recordsWithoutId = true;
        }
      }
    });

    if (recordsWithId === true) {
      const deleteAdlevelRecord = await deleteMultipleAdlevels(toBeDeletedRecordIds);

      if (deleteAdlevelRecord.success === true) {
        afterDeleteAction();
      } else {
        //show error notification
        toast("Sorry, something went wrong.", { type: "error" });
      }
    }

    if (recordsWithoutId === true) {
      afterDeleteAction();
    }
  };

  const cancelAddingNewRow = () => {
    setError(false);
    setAccuracyErrors({});
    setNewRowValues({
      record_index: "",
      nameCompany: "",
      campaign: "",
      bundleSize: "",
      nameAdLevel: "",
      numberAdLevel: "",
      gtin: "",
      description: "",
    });
    setAddNewRow({
      add: false,
      addFrom: "",
    });
    props.trackChangesInProgress(false);
  };

  const newRowJsx = () => {
    return (
      <TableRow>
        {/* ------ select checkbox ----- */}
        <StyledTableCell sx={{ padding: "0px" }} component="td" scope="row" className="text-wrapper">
          <StyledStack direction={"row"}></StyledStack>
        </StyledTableCell>
        {/* ----------- number adlevel -------------- */}
        <MediumWidthStyledTableCell sx={{ padding: "5px" }} component="td" scope="row" className="text-wrapper">
          <CustomTextInputLeftAligned
            variant="standard"
            className={"form-control adlevel_number_text"}
            margin="none"
            name="group_number"
            fullWidth={true}
            type={"text"}
            value={newRowValues.numberAdLevel}
            onChange={(e) => {
              setNewRowValues({
                ...newRowValues,
                nameCompany: props.retailer,
                campaign: props.campaignId,
                numberAdLevel: e.target.value.trim(),
              });
            }}
            error={error === true && typeof accuracyErrors.numberAdLevel !== "undefined" ? true : false}
            InputProps={
              error === true && typeof accuracyErrors.numberAdLevel !== "undefined"
                ? {
                    endAdornment: (
                      <InputAdornment position="end">{tooltipError(accuracyErrors.numberAdLevel)}</InputAdornment>
                    ),
                  }
                : {}
            }
          />
        </MediumWidthStyledTableCell>
        {/* -----------  name adlevel --------------- */}
        <StyledTableCell sx={{ padding: "5px" }} component="td" scope="row" className="text-wrapper">
          <CustomTextInputLeftAligned
            variant="standard"
            margin="none"
            // sx={{ maxWidth: "300px" }}
            fullWidth={true}
            name={"adlevel_name_text"}
            type={"text"}
            value={newRowValues.nameAdLevel}
            onChange={(e) => {
              setNewRowValues({
                ...newRowValues,
                nameCompany: props.retailer,
                campaign: props.campaignId,
                nameAdLevel: e.target.value.trim(),
              });
            }}
            error={error === true && typeof accuracyErrors.nameAdLevel !== "undefined" ? true : false}
            InputProps={
              error === true && typeof accuracyErrors.nameAdLevel !== "undefined"
                ? {
                    endAdornment: (
                      <InputAdornment position="end">{tooltipError(accuracyErrors.nameAdLevel)}</InputAdornment>
                    ),
                  }
                : { endAdornment: <InputAdornment position="end"></InputAdornment> }
            }
          />
        </StyledTableCell>
        {/* -------- Product description ------- */}
        <StyledTableCell sx={{ padding: "5px" }} component="td" scope="row" className="text-wrapper">
          <CustomTextInputLeftAligned
            variant="standard"
            margin="none"
            fullWidth={true}
            name={"artikel_desc"}
            type={"text"}
            value={newRowValues.description}
            onChange={(e) => {
              setNewRowValues({
                ...newRowValues,
                nameCompany: props.retailer,
                campaign: props.campaignId,
                description: e.target.value.trim(),
              });
            }}
            error={error === true && typeof accuracyErrors.description !== "undefined" ? true : false}
            InputProps={
              error === true && typeof accuracyErrors.description !== "undefined"
                ? {
                    endAdornment: (
                      <InputAdornment position="end">{tooltipError(accuracyErrors.description)}</InputAdornment>
                    ),
                  }
                : {}
            }
          />
        </StyledTableCell>
        {/* --------- GTIN ------- */}
        <MediumWidthStyledTableCell sx={{ padding: "5px" }} component="td" scope="row" className="text-wrapper">
          <CustomTextInputLeftAligned
            variant="standard"
            margin="none"
            fullWidth={true}
            name={"gtin"}
            type={"text"}
            value={newRowValues.gtin}
            onChange={(e) => {
              setNewRowValues({
                ...newRowValues,
                nameCompany: props.retailer,
                campaign: props.campaignId,
                gtin: e.target.value.trim(),
              });
            }}
            error={error === true && typeof accuracyErrors.gtin !== "undefined" ? true : false}
            InputProps={
              error === true && typeof accuracyErrors.gtin !== "undefined"
                ? {
                    endAdornment: <InputAdornment position="end">{tooltipError(accuracyErrors.gtin)}</InputAdornment>,
                  }
                : {}
            }
          />
        </MediumWidthStyledTableCell>
        {/* bundle size */}
        <MediumWidthStyledTableCell sx={{ padding: "5px" }} component="td" scope="row" className="text-wrapper">
          <CustomTextInputLeftAligned
            variant="standard"
            margin="none"
            fullWidth={true}
            name={"bundle_size_text"}
            type={"text"}
            value={newRowValues.bundleSize}
            onChange={(e) => {
              setNewRowValues({
                ...newRowValues,
                nameCompany: props.retailer,
                campaign: props.campaignId,
                bundleSize: e.target.value.trim(),
              });
            }}
            error={error === true && typeof accuracyErrors.bundleSize !== "undefined" ? true : false}
            InputProps={
              error === true && typeof accuracyErrors.bundleSize !== "undefined"
                ? {
                    endAdornment: <InputAdornment position="end">{tooltipError(accuracyErrors.bundleSize)}</InputAdornment>,
                  }
                : {}
            }
          />
        </MediumWidthStyledTableCell>
        {/* actions */}
        <MediumWidthStyledTableCell sx={{ padding: "0px" }} component="td" scope="row" className="text-wrapper">
          <Stack direction={"row"}>
            <HtmlTooltip title={"Save"}>
              <IconContainer>
                <a
                  href="#campaignSheetRecordEdit"
                  className="cyreen_icons"
                  aria-label="validateInlineEntries"
                  onClick={(e) => {
                    e.preventDefault();
                    addNewRecord(addNewRow.addFrom);
                  }}
                >
                  <Done fontSize="small" sx={{ color: "#AF3241", paddingTop: "7px" }} />
                </a>
              </IconContainer>
            </HtmlTooltip>
            <HtmlTooltip title={"Cancel"}>
              <IconContainer>
                <a
                  href="#campaignSheetUpload"
                  className="cyreen_icons"
                  onClick={(e) => {
                    e.preventDefault();
                    cancelAddingNewRow();
                  }}
                >
                  <Close fontSize="small" sx={{ color: "#AF3241", paddingTop: "7px" }} />
                </a>
              </IconContainer>
            </HtmlTooltip>
          </Stack>
        </MediumWidthStyledTableCell>
      </TableRow>
    );
  };

  const csvHeaders = [
    { label: "group_number", key: "numberAdLevel" },
    { label: "group_name", key: "nameAdLevel" },
    { label: "description", key: "description" },
    { label: "gtin", key: "gtin" },
    { label: "bundle_size", key: "bundleSize" },
  ];

  const tooltipError = (fieldError) => {
    return (
      <div>
        <HtmlTooltip title={fieldError}>
          <IconContainer>
            <Error aria-label={fieldError} fontSize="small" sx={{ color: "#AF3241", marginTop: "7px" }} />
          </IconContainer>
        </HtmlTooltip>
      </div>
    );
  };

  return (
    <>
      <Box>
        <Stack direction={"row"} spacing={4}>
          <h3>Existing analytics products</h3>
        </Stack>
        <Box height={40} />
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box width={300}>
            <CustomTextInput
              variant="standard"
              fullWidth
              label="Search"
              placeholder="Search"
              type={"text"}
              autoComplete="off"
              value={searchVal.value}
              onChange={(e) => {
                setSearchVal({ value: e.target.value });
              }}
              onBlur={(e) => {}}
            />
          </Box>
          {/* ------------ add new row and delete button ---------- */}
          <Box display="flex" alignItems="center" ml={2}>
            <Stack direction={"row"} spacing={2}>
              {readCampaignStatus === "requested" && (
                <>
                  {/* delete selected rows */}
                  {productSelection.length !== 0 ? (
                    <CloseButton
                      onClick={(e) => {
                        e.preventDefault();
                        setShowDeleteModal(true);
                      }}
                      label={"Delete Selected (" + productSelection.length + ")"}
                      icon={<Delete />}
                    />
                  ) : (
                    <DisabledGreyButton
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      label="Delete Selected (0)"
                      icon={<Delete />}
                    />
                  )}

                  {/* ------ add new adlevel row ------- */}
                  {(addNewRow.add === true && addNewRow.addFrom === "top") || editRow === true ? (
                    <DisabledGreyButton
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      label="Add new"
                      icon={<Add />}
                    />
                  ) : (
                    <ActionButton
                      onClick={(e) => {
                        e.preventDefault();
                        cancelAddingNewRow();
                        props.trackChangesInProgress(true);
                        setAddNewRow({
                          add: true,
                          addFrom: "top",
                        });
                      }}
                      label="Add new"
                      icon={<Add />}
                    />
                  )}
                </>
              )}
            </Stack>
          </Box>
        </Box>
        <Box height={20} />
        {/* ------- show data from uploaded sheet --------- */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="customized table">
            {/* ------ header row ------ */}
            <TableHead>
              <TableRow>
                {typeof sheetDetails.campaign_detail !== "undefined" &&
                  ![2, 4].includes(sheetDetails.campaign_detail.campaign_status_code) && (
                    <StyledTableCell sx={{ padding: "5px", fontSize: "13px" }} align="left" className="text-wrapper">
                      <StyledWhiteCheckbox
                        size="small"
                        checked={selectAll}
                        onChange={(e) => {
                          setSelectAll(e.target.checked);
                          checkUncheckAllCheckboxes(e.target.checked);
                          if (e.target.checked === true) {
                            let selectedItems = [];
                            sheetRecords.map((record, key) => {
                              selectedItems.push(record.record_index);
                            });
                            setProductSelection(selectedItems);
                          } else {
                            //reset the selection
                            setProductSelection([]);
                          }
                        }}
                      />
                    </StyledTableCell>
                  )}

                <MediumWidthStyledTableCell
                  sx={{ padding: "5px", fontSize: "13px", maxWidth: "100px" }}
                  align="left"
                  className="text-wrapper"
                >
                  <Stack direction={"row"} spacing={1}>
                    <div>Group No.</div>
                    {sortingMeasure.numberAdLevel === "asc" ? (
                      <ArrowDropDown
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setSortingMeasure((prevState) => {
                            return {
                              ...prevState,
                              numberAdLevel: prevState.numberAdLevel === "asc" ? "desc" : "asc",
                            };
                          });
                          setSortingParamName("numberAdLevel");
                          // sort the listing in redux state
                          // sortUploadedFilesList("fileName", sortingMeasure.fileName === "asc" ? "desc" : "asc");
                        }}
                      />
                    ) : (
                      <ArrowDropUp
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setSortingMeasure((prevState) => {
                            return {
                              ...prevState,
                              numberAdLevel: prevState.numberAdLevel === "asc" ? "desc" : "asc",
                            };
                          });
                          setSortingParamName("numberAdLevel");
                          // sort the listing in redux state
                          // sortUploadedFilesList("fileName", sortingMeasure.numberAdLevel === "asc" ? "desc" : "asc");
                        }}
                      />
                    )}
                  </Stack>
                </MediumWidthStyledTableCell>
                <StyledTableCell
                  sx={{ padding: "5px", fontSize: "13px", maxWidth: "50px" }}
                  align="left"
                  className="text-wrapper"
                >
                  <Stack direction={"row"} spacing={1}>
                    <div>Analysis Group Name</div>
                    {sortingMeasure.nameAdLevel === "asc" ? (
                      <ArrowDropDown
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setSortingMeasure((prevState) => {
                            return {
                              ...prevState,
                              nameAdLevel: prevState.nameAdLevel === "asc" ? "desc" : "asc",
                            };
                          });
                          setSortingParamName("nameAdLevel");
                          // sort the listing in redux state
                          // sortUploadedFilesList("fileName", sortingMeasure.fileName === "asc" ? "desc" : "asc");
                        }}
                      />
                    ) : (
                      <ArrowDropUp
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setSortingMeasure((prevState) => {
                            return {
                              ...prevState,
                              nameAdLevel: prevState.nameAdLevel === "asc" ? "desc" : "asc",
                            };
                          });
                          setSortingParamName("nameAdLevel");
                          // sort the listing in redux state
                          // sortUploadedFilesList("fileName", sortingMeasure.nameAdLevel === "asc" ? "desc" : "asc");
                        }}
                      />
                    )}
                  </Stack>
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "5px", fontSize: "13px" }} align="left" className="text-wrapper">
                  <Stack direction={"row"} spacing={1}>
                    <div>Article Description</div>
                    {sortingMeasure.description === "asc" ? (
                      <ArrowDropDown
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setSortingMeasure((prevState) => {
                            return {
                              ...prevState,
                              description: prevState.description === "asc" ? "desc" : "asc",
                            };
                          });
                          setSortingParamName("description");
                        }}
                      />
                    ) : (
                      <ArrowDropUp
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setSortingMeasure((prevState) => {
                            return {
                              ...prevState,
                              description: prevState.description === "asc" ? "desc" : "asc",
                            };
                          });
                          setSortingParamName("description");
                        }}
                      />
                    )}
                  </Stack>
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "5px", fontSize: "13px" }} align="left" className="text-wrapper">
                  <Stack direction={"row"} spacing={1}>
                    <div>GTIN</div>
                    {sortingMeasure.gtin === "asc" ? (
                      <ArrowDropDown
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setSortingMeasure((prevState) => {
                            return {
                              ...prevState,
                              gtin: prevState.gtin === "asc" ? "desc" : "asc",
                            };
                          });
                          setSortingParamName("gtin");
                          // sort the listing in redux state
                          // sortUploadedFilesList("fileName", sortingMeasure.gtin === "asc" ? "desc" : "asc");
                        }}
                      />
                    ) : (
                      <ArrowDropUp
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setSortingMeasure((prevState) => {
                            return {
                              ...prevState,
                              gtin: prevState.gtin === "asc" ? "desc" : "asc",
                            };
                          });
                          setSortingParamName("gtin");
                          // sort the listing in redux state
                          // sortUploadedFilesList("fileName", sortingMeasure.gtin === "asc" ? "desc" : "asc");
                        }}
                      />
                    )}
                  </Stack>
                </StyledTableCell>
                <MediumWidthStyledTableCell sx={{ padding: "5px", fontSize: "13px" }} align="left" className="text-wrapper">
                  <Stack direction={"row"} spacing={1}>
                    <div>Bundle Size</div>
                  </Stack>
                </MediumWidthStyledTableCell>
                {typeof sheetDetails.campaign_detail !== "undefined" &&
                  ![2, 4].includes(sheetDetails.campaign_detail.campaign_status_code) && (
                    <MediumWidthStyledTableCell
                      sx={{ padding: "5px", fontSize: "13px" }}
                      align="left"
                      className="text-wrapper"
                    >
                      Action
                    </MediumWidthStyledTableCell>
                  )}
              </TableRow>
            </TableHead>
            {/* -------- table body ------- */}
            <TableBody>
              {/* ------------- add new row from top ------------- */}
              {addNewRow.add === true && addNewRow.addFrom === "top" && newRowJsx()}
              {/* ------------- loop through uploaded records ---------- */}
              {sheetRecords.length !== 0 ? (
                sheetRecords.map((sheetData, key) => (
                  <TableRow key={"campaign_id_" + key}>
                    {/* ------ select checkbox ----- */}
                    {typeof sheetDetails.campaign_detail !== "undefined" &&
                      ![2, 4].includes(sheetDetails.campaign_detail.campaign_status_code) && (
                        <StyledTableCell
                          sx={{ padding: "0px", fontSize: "13px" }}
                          component="td"
                          scope="row"
                          className="text-wrapper"
                        >
                          <StyledStack direction={"row"}>
                            <StyledCheckbox
                              size="small"
                              checked={productSelection.includes(sheetData.record_index)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setProductSelection((prevSelection) => [...prevSelection, sheetData.record_index]);
                                } else {
                                  setProductSelection((prevSelection) =>
                                    prevSelection.filter((item) => item !== sheetData.record_index)
                                  );
                                }
                              }}
                            />
                          </StyledStack>
                        </StyledTableCell>
                      )}

                    {/* number adlevel */}
                    <MediumWidthStyledTableCell
                      sx={{ padding: "5px", fontSize: "13px" }}
                      component="td"
                      scope="row"
                      className="text-wrapper"
                    >
                      {editRow === true && editRowIndex === key ? (
                        <CustomTextInputLeftAligned
                          variant="standard"
                          className={"form-control adlevel_num_" + sheetData.numberAdLevel}
                          margin="none"
                          fullWidth={true}
                          type={"text"}
                          name={"group_number"}
                          value={editedRowValues.edited_values.numberAdLevel}
                          onChange={(e) => {
                            setEditedRowValues({
                              ...editedRowValues,
                              edited_values: {
                                ...editedRowValues.edited_values,
                                numberAdLevel: e.target.value.trim(),
                              },
                            });
                          }}
                          error={error === true && typeof accuracyErrors.numberAdLevel !== "undefined" ? true : false}
                          InputProps={
                            error === true && typeof accuracyErrors.numberAdLevel !== "undefined"
                              ? {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {tooltipError(accuracyErrors.numberAdLevel)}
                                    </InputAdornment>
                                  ),
                                }
                              : {}
                          }
                        />
                      ) : (
                        <div>{sheetData.numberAdLevel}</div>
                      )}
                    </MediumWidthStyledTableCell>
                    {/* name adlevel */}
                    <StyledTableCell
                      sx={{ padding: "5px", fontSize: "13px" }}
                      component="td"
                      scope="row"
                      className="text-wrapper"
                    >
                      {editRow === true && editRowIndex === key ? (
                        <CustomTextInputLeftAligned
                          variant="standard"
                          margin="none"
                          fullWidth={true}
                          name={"adlevel_name_text"}
                          type={"text"}
                          value={editedRowValues.edited_values.nameAdLevel}
                          onChange={(e) => {
                            setEditedRowValues({
                              ...editedRowValues,
                              edited_values: {
                                ...editedRowValues.edited_values,
                                nameAdLevel: e.target.value.trim(),
                              },
                            });
                          }}
                          error={error === true && typeof accuracyErrors.nameAdLevel !== "undefined" ? true : false}
                          InputProps={
                            error === true && typeof accuracyErrors.nameAdLevel !== "undefined"
                              ? {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {tooltipError(accuracyErrors.nameAdLevel)}
                                    </InputAdornment>
                                  ),
                                }
                              : {}
                          }
                        />
                      ) : (
                        <div>{sheetData.nameAdLevel}</div>
                      )}
                    </StyledTableCell>
                    {/* -------- Product description ------- */}
                    <StyledTableCell
                      sx={{ padding: "5px", fontSize: "13px" }}
                      component="td"
                      scope="row"
                      className="text-wrapper"
                    >
                      {editRow === true && editRowIndex === key ? (
                        <CustomTextInputLeftAligned
                          variant="standard"
                          margin="none"
                          fullWidth={true}
                          name={"artikel_desc"}
                          type={"text"}
                          value={editedRowValues.edited_values.description}
                          onChange={(e) => {
                            setEditedRowValues({
                              ...editedRowValues,
                              edited_values: {
                                ...editedRowValues.edited_values,
                                description: e.target.value.trim(),
                              },
                            });
                          }}
                          error={error === true && typeof accuracyErrors.description !== "undefined" ? true : false}
                          InputProps={
                            error === true && typeof accuracyErrors.description !== "undefined"
                              ? {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {tooltipError(accuracyErrors.description)}
                                    </InputAdornment>
                                  ),
                                }
                              : {}
                          }
                        />
                      ) : (
                        <div>{sheetData.description}</div>
                      )}
                    </StyledTableCell>
                    {/* --------- GTIN ------- */}
                    <MediumWidthStyledTableCell
                      sx={{ padding: "5px", fontSize: "13px" }}
                      component="td"
                      scope="row"
                      className="text-wrapper"
                    >
                      {editRow === true && editRowIndex === key ? (
                        <CustomTextInputLeftAligned
                          variant="standard"
                          margin="none"
                          fullWidth={true}
                          name={"gtin"}
                          type={"text"}
                          value={editedRowValues.edited_values.gtin}
                          onChange={(e) => {
                            setEditedRowValues({
                              ...editedRowValues,
                              edited_values: {
                                ...editedRowValues.edited_values,
                                gtin: e.target.value.trim(),
                              },
                            });
                          }}
                          error={error === true && typeof accuracyErrors.gtin !== "undefined" ? true : false}
                          InputProps={
                            error === true && typeof accuracyErrors.gtin !== "undefined"
                              ? {
                                  endAdornment: (
                                    <InputAdornment position="end">{tooltipError(accuracyErrors.gtin)}</InputAdornment>
                                  ),
                                }
                              : {}
                          }
                        />
                      ) : (
                        <div>{sheetData.gtin}</div>
                      )}
                    </MediumWidthStyledTableCell>
                    {/* bundle size */}
                    <MediumWidthStyledTableCell sx={{ padding: "5px" }} component="td" scope="row" className="text-wrapper">
                      {editRow === true && editRowIndex === key ? (
                        <CustomTextInputLeftAligned
                          variant="standard"
                          margin="none"
                          fullWidth={true}
                          name={"bundle_size_text"}
                          type={"text"}
                          value={editedRowValues.edited_values.bundleSize}
                          onChange={(e) => {
                            setEditedRowValues({
                              ...editedRowValues,
                              edited_values: {
                                ...editedRowValues.edited_values,
                                bundleSize: e.target.value.trim(),
                              },
                            });
                          }}
                          error={error === true && typeof accuracyErrors.bundleSize !== "undefined" ? true : false}
                          InputProps={
                            error === true && typeof accuracyErrors.bundleSize !== "undefined"
                              ? {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {tooltipError(accuracyErrors.bundleSize)}
                                    </InputAdornment>
                                  ),
                                }
                              : {}
                          }
                        />
                      ) : (
                        <div>{sheetData.bundleSize}</div>
                      )}
                    </MediumWidthStyledTableCell>
                    {/* actions */}
                    {typeof sheetDetails.campaign_detail !== "undefined" &&
                      ![2, 4].includes(sheetDetails.campaign_detail.campaign_status_code) && (
                        <MediumWidthStyledTableCell
                          sx={{ padding: "0px", fontSize: "13px" }}
                          component="td"
                          scope="row"
                          className="text-wrapper"
                        >
                          {/* ---- edit and delete shouldn't be visible when report is requested or already available ---- */}
                          {typeof sheetDetails.campaign_detail !== "undefined" &&
                            ![2, 4].includes(sheetDetails.campaign_detail.campaign_status_code) && (
                              <Stack direction={"row"}>
                                {editRow === true && editRowIndex !== key ? (
                                  <>
                                    {/* ------------ disabled buttons ----------- */}
                                    <HtmlTooltipDisabled title={"Disabled"}>
                                      <IconContainer>
                                        <a
                                          href="#campaignSheetRecordEdit"
                                          className="cyreen_icons"
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <Edit fontSize="small" sx={{ color: "#E6E6E6", paddingTop: "7px" }} />
                                        </a>
                                      </IconContainer>
                                    </HtmlTooltipDisabled>
                                    <HtmlTooltipDisabled title={"Disabled"}>
                                      <IconContainer>
                                        <a
                                          href="#campaignSheetUpload"
                                          className="cyreen_icons"
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <Delete fontSize="small" sx={{ color: "#E6E6E6", paddingTop: "7px" }} />
                                        </a>
                                      </IconContainer>
                                    </HtmlTooltipDisabled>
                                  </>
                                ) : editRow === true && editRowIndex === key ? (
                                  <>
                                    <HtmlTooltip title={"Save"}>
                                      <IconContainer>
                                        <a
                                          href="#campaignSheetRecordEdit"
                                          className="cyreen_icons"
                                          aria-label="validateInlineEntries"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleFieldChange(sheetData.record_index);
                                          }}
                                        >
                                          <Done fontSize="small" sx={{ color: "#AF3241", paddingTop: "7px" }} />
                                        </a>
                                      </IconContainer>
                                    </HtmlTooltip>
                                    <HtmlTooltip title={"Cancel"}>
                                      <IconContainer>
                                        <a
                                          href="#campaignSheetUpload"
                                          className="cyreen_icons"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setEditRow(false);
                                            setError(false);
                                            setAccuracyErrors({});
                                            setEditRowIndex("");
                                            setEditedRowValues({});
                                            props.trackChangesInProgress(false);
                                          }}
                                        >
                                          <Close fontSize="small" sx={{ color: "#AF3241", paddingTop: "7px" }} />
                                        </a>
                                      </IconContainer>
                                    </HtmlTooltip>
                                  </>
                                ) : (
                                  <>
                                    <HtmlTooltip title={"Edit"}>
                                      <IconContainer>
                                        <a
                                          href="#campaignSheetRecordEdit"
                                          className="cyreen_icons"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            cancelAddingNewRow();
                                            setEditRow(true);
                                            setEditRowIndex(key);
                                            props.trackChangesInProgress(true);
                                            setEditedRowValues({
                                              original_record: sheetData,
                                              edited_values: sheetData,
                                            });
                                          }}
                                        >
                                          <Edit fontSize="small" sx={{ color: "#AF3241", paddingTop: "7px" }} />
                                        </a>
                                      </IconContainer>
                                    </HtmlTooltip>
                                    <HtmlTooltip title={"Delete"}>
                                      <IconContainer>
                                        <a
                                          href="#campaignSheetUpload"
                                          className="cyreen_icons"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShowDeleteModal(true);
                                            setProductSelection((prevSelection) => [
                                              ...prevSelection,
                                              sheetData.record_index,
                                            ]);
                                          }}
                                        >
                                          <Delete fontSize="small" sx={{ color: "#AF3241", paddingTop: "7px" }} />
                                        </a>
                                      </IconContainer>
                                    </HtmlTooltip>
                                  </>
                                )}
                              </Stack>
                            )}
                        </MediumWidthStyledTableCell>
                      )}
                  </TableRow>
                ))
              ) : addNewRow.add === true ? (
                <></>
              ) : (
                <TableRow>
                  <StyledTableCell component="td" scope="row" className="text-wrapper" colSpan={7}>
                    {searchVal.value !== "" ? "No results" : "No Analytics Products added yet"}
                  </StyledTableCell>
                </TableRow>
              )}
              {/* -------- add new row from bottom ------- */}
              {addNewRow.add === true && addNewRow.addFrom === "bottom" && newRowJsx()}
              {/* -------- add new button ------- */}
              <TableRow>
                {typeof sheetDetails.campaign_detail !== "undefined" &&
                  ![2, 4].includes(sheetDetails.campaign_detail.campaign_status_code) && (
                    <StyledTableCell
                      sx={{ padding: "0px" }}
                      component="td"
                      scope="row"
                      className="text-wrapper"
                    ></StyledTableCell>
                  )}

                {/* number adlevel */}
                <StyledTableCell
                  sx={{ padding: "0px" }}
                  component="td"
                  scope="row"
                  className="text-wrapper"
                ></StyledTableCell>
                {/* name adlevel */}
                <StyledTableCell
                  sx={{ padding: "0px" }}
                  component="td"
                  scope="row"
                  className="text-wrapper"
                ></StyledTableCell>
                {/* -------- Product description ------- */}
                <StyledTableCell
                  sx={{ padding: "0px" }}
                  component="td"
                  scope="row"
                  className="text-wrapper"
                ></StyledTableCell>
                {/* --------- GTIN ------- */}
                <StyledTableCell
                  sx={{ padding: "0px" }}
                  component="td"
                  scope="row"
                  className="text-wrapper"
                ></StyledTableCell>
                {/* bundle size */}
                <StyledTableCell
                  sx={{ padding: "0px" }}
                  component="td"
                  scope="row"
                  className="text-wrapper"
                ></StyledTableCell>
                {/* actions */}
                {typeof sheetDetails.campaign_detail !== "undefined" &&
                  ![2, 4].includes(sheetDetails.campaign_detail.campaign_status_code) && (
                    <MediumWidthStyledTableCell sx={{ padding: "0px" }} component="td" scope="row" className="text-wrapper">
                      <Stack direction={"row"}>
                        {(addNewRow.add === true && addNewRow.addFrom === "bottom") || editRow === true ? (
                          <HtmlTooltipDisabled title={"Disabled"}>
                            <IconContainer>
                              <a
                                href="#campaignSheetRecordEdit"
                                className="cyreen_icons"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <AddCircle fontSize="small" sx={{ color: "#E6E6E6", paddingTop: "7px" }} />
                              </a>
                            </IconContainer>
                          </HtmlTooltipDisabled>
                        ) : (
                          <HtmlTooltip title={"Add New"}>
                            <IconContainer>
                              <a
                                href="#campaignSheetRecordEdit"
                                className="cyreen_icons"
                                onClick={(e) => {
                                  e.preventDefault();
                                  cancelAddingNewRow();
                                  props.trackChangesInProgress(true);
                                  setAddNewRow({
                                    add: true,
                                    addFrom: "bottom",
                                  });
                                }}
                              >
                                <AddCircle fontSize="small" sx={{ color: "#AF3241", paddingTop: "7px" }} />
                              </a>
                            </IconContainer>
                          </HtmlTooltip>
                        )}
                      </Stack>
                    </MediumWidthStyledTableCell>
                  )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* --------- record count info --------- */}
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box mt={2} id="infoModalText" sx={{ paddingLeft: 0 }}>
            <Pagination
              totalCount={listingRecords.length}
              reduxStartKeyword="analysis_import_start"
              reduxEndKeyword="analysis_import_end"
            />
            {/* <StyledDarkRedInformationTypography variant="body2">
              Showing {sheetRecords.length} out of {listingRecords.length} results.
            </StyledDarkRedInformationTypography> */}
          </Box>
          <Box display="flex" alignItems="center" ml={2} mt={2}>
            <DownloadCSV
              csvData={sortedResults(listingRecords)}
              csvHeaders={csvHeaders}
              filename={
                typeof sheetDetails.campaign_detail !== "undefined"
                  ? "export_" + sheetDetails.campaign_detail.campaign_name.replaceAll(" ", "_") + "_products.csv"
                  : "products.csv"
              }
            />
          </Box>
        </Box>

        <Box height={40} />
      </Box>

      {/* ----------- delete adlevel confirmation modal -------- */}
      {showDeleteModal === true && (
        <CommonConfirmation
          confirmationActionCall={() => {
            deleteSelectedItems();
          }}
          closePopup={(e) => {
            setShowDeleteModal(false);
          }}
          confirmTitle="Delete Analytics Product(s)"
          showConfirmationPopup={showDeleteModal}
          confirmationMessage="Are you sure you want to delete the selected Analytics Product(s)"
        />
      )}
    </>
  );
};

export default AdlevelSheetResults;
