import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import { tabs } from "./Dashboards/monitoringDashboardConfirg";
import { StyledMainBox, StyledTabs } from "../../components/commonComponents/StyledComponents/styled";
import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { loadStores } from "../../api_calls/campaigns";
import { useSelector } from "react-redux";

// to load tab content
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Monitoring = () => {
  const userStateDetails = useSelector((state) => state.userSelections);
  const [value, setValue] = React.useState(0);
  const tabsConfig = tabs();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    loadStores("receipt-monitoring");
  }, []);

  return (
    <StyledMainBox component="main">
      {typeof userStateDetails.stores_list !== "undefined" && (
        <>
          <Box
            sx={{
              display: "flex", // Make the Box a flex container
              borderBottom: 1,
              borderColor: "divider",
              position: "sticky",
              top: "0",
              zIndex: 1,
              backgroundColor: "#fff",
            }}
          >
            <Box component={"div"}>
              <StyledTabs value={value} onChange={handleChange} aria-label="brand analytics" textColor={"#F04B55"}>
                {tabsConfig.map((tabDetail) => {
                  return <Tab key={tabDetail.unique_key} icon={tabDetail.icon} label={tabDetail.label} {...a11yProps(0)} />;
                })}
              </StyledTabs>
            </Box>
            {/* ----------- filters section ------------ */}
            <Box
              component={"div"}
              sx={{
                flex: 1,
              }}
            >
              {tabsConfig[value].filter_component}
            </Box>
          </Box>

          {/* ============== tab contents =========== */}
          {tabsConfig.map((tabDetail, index) => {
            return (
              <CustomTabPanel value={value} index={index}>
                {tabDetail.dashboard}
              </CustomTabPanel>
            );
          })}
        </>
      )}
    </StyledMainBox>
  );
};

export default AccessControl(Monitoring, ["infra_store_monitoring_can_view"], [], ["infrastructure_section", "monitoring_infra"]);
